<template>
  <div
    style="padding: 50px 0; width: 100%; height: 90%; overflow-y: scroll"
    class="fadeIn"
  >
    <!-- 参数详情 -->
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="9999"
    />

    <div class="historyItme" v-for="item in listData" :key="item.id">
      <h2>{{ item.title }}</h2>
      <div
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 100px;
        "
      >
        <div>
          <span style="font-size: 25px; text-decoration: underline">
            {{ item.relaData }}</span
          ><br />
          <span style="color: #3dc7d4"> {{ "平均" + item.title }}</span>
        </div>
        <div
          class="colorItem flexD"
          style="
            display: flex;
            flex-direction: column;
            height: 120px;
            justify-content: space-around;
          "
        >
          <div v-for="(it, index) in item.list" :key="index">
            {{ it.type }} <i style="display: inline-block; margin: 0 5px">|</i>
            <span style="font-weight: 700"> {{ it.value }} </span>{{ item.tag }}
          </div>
        </div>
      </div>
    </div>
    <!-- 用电量 -->
    <div class="historyItme">
      <h2>用电量</h2>
      <div style="width: 100%; height: 130px" id="pie"></div>
    </div>
    <!-- 功率因数 -->
    <div class="historyItme">
      <h2>功率因数</h2>
      <el-row style="padding: 0 10px; margin-top: 10px">
        <el-col :span="8">总功率因数</el-col>
        <el-col :span="6">A</el-col>
        <el-col :span="7">B</el-col>
        <el-col :span="2">C</el-col>
      </el-row>
      <!-- <div style="display: flex; justify-content: space-between;padding: 0 10px;">
        <div style="width: 25%;">总功率因数</div>
        <div>A</div>
        <div>B</div>
        <div>C</div>
      </div> -->
      <div style="display: flex">
        <div
          v-for="(item, index) in power"
          style="width: 20%; height: 150px; padding: 10px; position: relative"
          :key="index"
        >
          <div class="progressBox">
            <el-progress
              :color="item.color"
              type="dashboard"
              :percentage="item.value"
              :width="70"
              :stroke-width="4"
              :show-text="false"
            ></el-progress>
          </div>
          <div
            style="
              position: absolute;
              top: 20%;
              left: 17%;
              text-align: center;
              font-size: 12px;
            "
          >
            <span
              style="font-size: 16px; font-weight: 700"
              :style="'color:' + item.color"
            >
              {{ item.value }}</span
            >
            <p style="color: #08ae9e; margin-top: 5px; font-size: 10px">
              功率因数
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 电压频率 -->
    <div class="historyItme">
      <h2>电压频率</h2>
      <div style="display: flex">
        <div
          style="
            width: 40%;
            height: 180px;
            position: relative;
            transform: translate(20px, -10px);
          "
        >
          <div class="progressBox" style="margin: 0 auto">
            <el-progress
              color="#0A91F0"
              type="dashboard"
              :percentage="frequency"
              :width="80"
              :stroke-width="5"
              :show-text="false"
            ></el-progress>
          </div>
          <h3
            style="position: absolute; top: 30%; left: 15%; text-align: center"
          >
            {{ frequency }}
            <p style="color: #08ae9e">Hz</p>
          </h3>
        </div>
        <div
          style="
            height: 130px;
            width: 60%;
            display: flex;
            padding: 0 10px 10px;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div>A相接线：<br />{{ wiring.A | getText }}</div>
          <div>B相接线：<br />{{ wiring.B | getText }}</div>
          <div>C相接线：<br />{{ wiring.C | getText }}</div>
        </div>
      </div>
    </div>
    <!-- ***** -->
    <div class="historyItme">
      <van-row>
        <van-col :span="7"> <span>电压均极值曲线</span></van-col>
        <van-col :span="4">
          <van-icon
            @click="(timeType = 1), (sheetShow = true)"
            name="setting-o"
            color="#1d82fe"
          />
        </van-col>
      </van-row>
      <div id="power" style="width: 100%; height: 130px"></div>
    </div>
    <van-popup
      v-model="sheetShow"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-if="timeType == 1"
        v-model="powerDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @confirm="getPowerFactor"
      />
    </van-popup>
  </div>
</template>
<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      frequency: 0,
      wiring: {
        A: "",
        B: "",
        C: "",
      },
      power: [
        { value: 80, color: "#088eee", tag: "FT" },
        { value: 50, color: "#F16F1E", tag: "FA" },
        { value: 20, color: "#65c403", tag: "FB" },
        { value: 90, color: "#FB5959", tag: "FC" },
      ],
      pickerMinDate: "",
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime();
          if (maxDate) {
            this.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate !== "") {
            const day30 = (30 - 1) * 24 * 3600 * 1000;
            // 只选后30天，minTime不用加day30,只选前30天，maxTime不用加day30
            let maxTime = this.pickerMinDate + day30;
            let minTime = this.pickerMinDate - day30;
            if (maxTime > new Date()) {
              maxTime = new Date();
            }
            return time.getTime() > maxTime || time.getTime() < minTime;
          }
          return time.getTime() > Date.now();
        },
      },
      voltageDate: [],
      loading1: false,
      zoom: 1,
      listData: [
        {
          id: 1,
          title: "电压",
          param: "UAV",
          relaData: "216.37v",
          tag: "V",
          list: [
            { type: "UA", value: "224.13" },
            { type: "UB", value: "224.13" },
            { type: "UC", value: "224.13" },
          ],
        },
        {
          id: 2,
          title: "电流",
          param: "IAV",
          relaData: "216.37A",
          tag: "A",
          list: [
            { type: "IA", value: "224.13" },
            { type: "IB", value: "224.13" },
            { type: "IC", value: "224.13" },
          ],
        },
        {
          id: 3,
          title: "功率",
          param: "PAV",
          relaData: "216.37KW",
          tag: "Kw",
          list: [
            { type: "PA", value: "224.13" },
            { type: "PB", value: "224.13" },
            { type: "PC", value: "224.13" },
          ],
        },
      ],
      ConnectionCheck: [
        {
          id: 0,
          text: "正常",
        },
        {
          id: 1,
          text: "电流互感器接反",
        },
        {
          id: 2,
          text: "电流相序错误，当前相序为A",
        },
        {
          id: 3,
          text: "电流相序错误，当前相序为B",
        },
        {
          id: 4,
          text: "电流相序错误，当前相序为C",
        },
        {
          id: 5,
          text: "电压相序错误，当前相序为A",
        },
        {
          id: 6,
          text: "电压相序错误，当前相序为B",
        },
        {
          id: 7,
          text: "电压相序错误，当前相序为C",
        },
        {
          id: 8,
          text: "无参数",
        },
      ],
      powerDate: new Date(),
      powerDateT: new Date(),
      powerDateH: new Date(),
      maxDate: new Date(),
      sheetShow: false,
      show: false,
      title: this.$route.query.pjName,
      progressShow: false,
      contrast: {},
      electricity: true,
      voltage: true,
      setHistory(title, xData, yData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            extraCssText: "z-index:999999",
            confine: true,
          },
          legend: {
            color: "#000",
            right: "5%",
            top: "3%",
            // orient: "vertical",
            icon: "circle",
            itemStyle: {
              textColor: "#000",
            },
          },
          grid: {
            left: "30",
            top: "30",
            bottom: "20%",
            right: "5%",
          },
          xAxis: {
            data: xData,
            type: "category",
            boundaryGap: true,
            axisLabel: {
              inside: false,
              color: "#000",
              // formatter: function (value) {
              //   if (value.length > 8) {
              //     return `${value.slice(0, 8)}...`;
              //   }
              //   return value;
              // },
            },
            axisLine: {
              lineStyle: {
                color: "#000",
              },
            },
          },
          yAxis: {
            splitNumber: 4,
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000",
              },
            },
            axisLabel: {
              inside: false,
              color: "#000",
            },
            splitLine: {
              lineStyle: {
                color: "#000",
                width: 0.8,
                type: "dashed",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          // series: data,
          series: [
            {
              smooth: true,
              name: title[0],
              type: "line",
              itemStyle: {
                color: "#ecbf18",
              },
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(141, 227, 228, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(160, 229, 236, 0.1)",
                    },
                  ],
                },
              },
              data: yData[0],
            },
            {
              smooth: true,
              name: title[1],
              type: "line",
              itemStyle: {
                color: "#19c572",
              },
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(141, 227, 228, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(160, 229, 236, 0.1)",
                    },
                  ],
                },
              },
              data: yData[1],
            },
            {
              smooth: true,
              name: title[2],
              type: "line",
              itemStyle: {
                color: "#e01428",
              },
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(141, 227, 228, 0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(160, 229, 236, 0.1)",
                    },
                  ],
                },
              },
              data: yData[2],
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  filters: {
    getText(val) {
      const check = that.ConnectionCheck.filter((item) => item.id == val);
      return check[0].text;
    },
  },
  created() {
    that = this;
    this.getPower();
    // that.powerDate = this.dayjs().format("YYYY-MM-DD");
    // that.powerDateT = this.dayjs().format("YYYY-MM-DD");
    // that.powerDateH = this.dayjs().format("YYYY-MM-DD");
  },
  methods: {
    setPieTwo(data, data1, ref, title) {
      let option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          show: true,
          text: title.value,
          subtext: title.name,
          x: "center",
          y: "center",
          itemGap: 2,
          textStyle: {
            color: "#000",
            fontSize: 10,
            fontStyle: "normal",
            fontWeight: "700",
          },
          subtextStyle: {
            color: "#000",
            fontSize: 10,
            fontStyle: "normal",
            fontWeight: "700",
          },
        },
        series: [
          {
            z: 1,
            name: "用电量详情",
            type: "pie",
            radius: ["40%", "65%"],
            avoidLabelOverlap: false,
            label: {
              alignTo: "edge",
              show: true,
              fontWeight: 700,
              formatter: (params) => {
                return (
                  "{b|" +
                  params.name +
                  "}" +
                  "\n" +
                  "{a|" +
                  "占比：" +
                  +params.percent +
                  "%}" +
                  "\n" +
                  "{c|" +
                  "当前值：" +
                  +params.value +
                  "}"
                );
              },
              rich: {
                a: {
                  fontSize: 10,
                  color: "inherit",
                  lineHeight: 10,
                  align: "center",
                },
                b: {
                  fontSize: 10,
                  height: 10,
                  align: "center",
                },
                c: {
                  fontSize: 10,
                  color: "inherit",
                  lineHeight: 15,
                  align: "center",
                },
              },
            },
            labelLayout: (params) => {
              let x = "";
              let y = "";
              if (params.dataIndex == 0) {
                x = params.rect.x + 150;
                y = params.rect.y + 30;
              } else if (params.dataIndex == 1) {
                x = params.rect.x - 10;
                y = params.rect.y + 35;
              } else if (params.dataIndex == 2) {
                x = params.rect.x - 20;
                y = params.rect.y + 20;
              }

              return {
                x: x,
                y: y,
                verticalAlign: "middle",
                align: "right",
              };
            },
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: "#fff",
              borderRadius: 0,
            },
            emphasis: {
              label: {
                show: true,
              },
              scale: true,
              scaleSize: 20,
            },
            labelLine: {
              show: false,
            },
            data: data,
          },
          {
            z: 2,
            name: "外边框",
            type: "pie",
            avoidLabelOverlap: false,
            tooltip: {
              show: false,
            },
            clockWise: false,
            hoverAnimation: false,
            radius: ["59%", "90%"],
            label: {
              normal: {
                show: false,
              },
            },
            data: data1,
          },
        ],
      };
      var chartDom = document.getElementById(ref);
      let myChart = echarts.init(chartDom);
      myChart.clear();
      myChart.setOption(option);
    },
    getrelaData() {
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: [
          "UA",
          "UB",
          "UC",
          "IA",
          "IB",
          "IC",
          "UF",
          "PA",
          "PB",
          "PC",
          "UAV",
          "IAV",
          "PAV",
          "FA",
          "FB",
          "FC",
          "FT",
          "EPA",
          "EPB",
          "EPC",
          "EPT",
          "SBY2",
          "SBY3",
          "SBY4",
        ],
      };
      this.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let obj = {};
          res.data.forEach((item) => {
            obj[item.paramTag] = item.value * 1;
          });
          that.listData.forEach((item) => {
            item.relaData = obj[item.param] || 0;
            item.list.forEach((it) => {
              it.value = obj[it.type] || 0;
            });
          });
          that.power.forEach((item) => {
            item.value = obj[item.tag] || 0;
          });
          that.frequency = obj["UF"] || 0;
          this.wiring = {
            A: obj["SBY2"] < 8 ? obj["SBY2"] : 8,
            B: obj["SBY3"] < 8 ? obj["SBY3"] : 8,
            C: obj["SBY4"] < 8 ? obj["SBY4"] : 8,
          };
          let data = [
            {
              name: "A相有功电能",
              value: obj["EPA"],
              itemStyle: { color: "rgb(36, 230, 175)" },
            },
            {
              name: "B相有功电能",
              value: obj["EPB"],
              itemStyle: { color: "rgb(120, 205, 243)" },
            },
            {
              name: "C相有功电能",
              value: obj["EPC"],
              itemStyle: { color: "rgb(254, 164, 164)" },
            },
          ];
          let data1 = [
            {
              value: obj["EPC"],
              name: "",
              itemStyle: {
                color: "rgba(254, 164, 164, 0.2)",
              },
            },
            {
              value: obj["EPB"], //此处的值无所谓是多少
              name: "", //因为不展示label，可不填
              itemStyle: {
                color: "rgba(120, 205, 243,0.2)",
              },
            },
            {
              value: obj["EPA"],
              name: "",
              itemStyle: {
                color: "rgba(36, 230, 175,0.2)",
              },
            },
          ];
          this.setPieTwo(data, data1, "pie", {
            name: "用电量",
            value: obj["EPT"],
          });
        }
      });
    },
    getPowerFactor() {
      that.sheetShow = false;
      let from = {
        startDate: this.dayjs(that.powerDate).format("YYYY-MM-DD"),
        endDate: this.dayjs(that.powerDate).format("YYYY-MM-DD"),
        projectId: this.$route.query.projectId * 1,
        paramTags: ["UMA", "UAV", "UMI"],
        historyType: 1,
      };
      this.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let title = [];
          let xData = res.data.date;
          let yData = [];
          res.data.data.forEach((item) => {
            let newData = [];
            for (let key in item.stream) {
              newData.push([key, item.stream[key]]);
            }
            title.push(item.name);
            yData.push(newData);
          });
          that.setHistory(title, xData, yData, "power");
        }
      });
    },
    // 获取功率因素
    getPower() {
      let from = {
        projectId: this.$route.query.projectId,
        paramTags: [
          "PA",
          "PB",
          "PC",
          "PT",
          "QA",
          "QB",
          "QC",
          "QT",
          "SA",
          "SB",
          "SC",
          "ST",
          "FA",
          "FB",
          "FC",
          "FT",
        ],
      };
      that.$api.energy.getParams(from).then((res) => {
        if (res.code == 200) {
          let A = ["PA", "QA", "SA", "FA"];
          let B = ["PB", "QB", "SB", "FB"];
          let C = ["PC", "QC", "SC", "FC"];
          let H = ["PT", "QT", "ST", "FT"];
          res.data.forEach((item) => {
            if (A.includes(item.paramTag)) {
              that.progressData.A.push(item.value);
            } else if (B.includes(item.paramTag)) {
              that.progressData.B.push(item.value);
            } else if (C.includes(item.paramTag)) {
              that.progressData.C.push(item.value);
            } else if (H.includes(item.paramTag)) {
              that.progressData.H.push(item.value);
            }
          });
          setTimeout(() => {
            that.progressShow = true;
          }, 100);
        }
      });
    },
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    this.getrelaData();
    that.getPowerFactor();
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.historyItme {
  margin: 0 10px 10px;
  border-radius: 2px 20px 2px 20px;
  width: 90%;
  height: 150px;
  text-align: left;
  padding: 10px;
  font-weight: 700;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.colorItem div:nth-child(1) span {
  color: #24e6af;
}
.colorItem div:nth-child(2) span {
  color: #ed9130;
}
.colorItem div:nth-child(3) span {
  color: #29a3f2;
}
.progressBox {
  width: 100px;
  height: 100%;
  transform: translate(10px, -55px) rotate(40deg) rotateX(180deg);
  transform-origin: center center;
}
/* @import url(); 引入css类 */
</style>
